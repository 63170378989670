body {
  background-color: #fff;
  color: #444444;
  font-family: Arial, sans-serif;
  font-size: 16px;
}
.wrapper {
  max-width: 770px;
}
h4 {
  font-weight: bold;
}
input[type="radio"] {
  cursor: pointer;
}
.gray {
  color: #868e9d;
}
.red {
  color: red;
}
.pink {
  color: #e84e93;
}
.cursor-pointer {
  cursor: pointer;
}
.custom-textbox {
  border-color: #868e9d;
  border-radius: 0;
}
.custom-button {
  background-color: #e84e93;
  color: #fff;
  border: 0;
  border-radius: 6px;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.custom-button:disabled {
  background-color: darkgray;
  color: #fff;
}
.react-datepicker-wrapper {
  width: 100%;
}
.title-box {
  background-color: #000;
  color: #fff;
  padding-top: 10px;
}
.pdf-icon {
  margin-top: -3px;
  width: 50px;
  height: 40px;
}
.pdf-button {
  display: inline-block;
  max-width: 315px;
}